export class TabsComponent extends HTMLElement {
  constructor() {
    super();
    this._target = this.getAttribute("target");
  }

  connectedCallback() {
    this.boundToggleTabOnEvent = this.toggleTabOnEvent.bind(this);
    this.init();
  }

  // TODO :: for use when in a shared component repo
  // startComponentObserver() {
  //   this.observer = new MutationObserver((mutations) => {
  //     mutations.forEach((mutation) => {
  //       if (
  //         mutation.type === "attributes" &&
  //         this.getAttribute("target") != this._target
  //       ) {
  //         this.toggleActiveTab();
  //       }
  //     });
  //   });
  //   this.observer.observe(this, {
  //     attributes: true,
  //   });
  // }

  // stopComponentObserver() {
  //   this.observer.disconnect();
  // }

  disconnectedCallback() {
    this.navs.forEach((nav) =>
      nav.removeEventListener("click", this.boundToggleTabOnEvent)
    );
  }

  toggleTabOnEvent(ev) {
    ev.preventDefault();
    ev.stopImmediatePropagation();
    const target = ev.target.closest("tab-nav-item")._target;
    this._target = target;
    this.toggleActiveTab();
  }

  toggleActiveTab(assignedTarget = null) {
    if (assignedTarget) {
      this._target = assignedTarget;
    }
    if (this.getAttribute("target") != this._target) {
      this.setAttribute("target", this._target);
    }
    [...this.navs, ...this.tabs].forEach((el) => {
      el.toggleActiveTabByTarget(this._target);
      if (el.closest("tab-content-item")) {
        el.closest("tab-content-item").toggleHiddenWhenInactive();
      }
    });
    const self = this;
    this._valid = [...this.navs].filter((nav) => {
      return nav._target === self.getAttribute("target");
    });
  }

  init() {
    this.navs = this.querySelectorAll("tab-nav-item");
    this.tabs = this.querySelectorAll("tab-content-item");
    this.navs.forEach((navEl) => {
      navEl.addEventListener("click", this.boundToggleTabOnEvent);
      navEl.addEventListener("keydown", (ev) => {
        if (ev.key === "Enter") {
          this.toggleTabOnEvent(ev);
        }
      });
    });
  }
}

export class TabNavigationItem extends HTMLElement {
  constructor() {
    super();
  }

  connectedCallback() {
    this._target = this.getAttribute("target");
    this.toggleDisabled();
    this.toggleAriaSelected();
    this.render();
  }

  setDisabled() {
    this.classList.add("disabled");
    this.firstElementChild.tabIndex = -1;
  }

  setEnabled() {
    this.classList.remove("disabled");
    this.firstElementChild.tabIndex = 0;
  }

  toggleDisabled() {
    if (this.classList.contains("disabled")) {
      this.setDisabled();
    } else {
      this.setEnabled();
    }
  }

  toggleAriaSelected() {
    if (this.classList.contains("active")) {
      this.firstElementChild.setAttribute("aria-selected", true);
    } else {
      this.firstElementChild.setAttribute("aria-selected", false);
    }
  }

  toggleActiveTabByTarget(target) {
    if (this._target === target) {
      this.classList.add("active");
    } else {
      this.classList.remove("active");
    }
    this.toggleAriaSelected();
    this.toggleDisabled();
  }

  static get observedAttributes() {
    return ["target"];
  }

  attributeChangedCallback(attr, prev, next) {
    if (prev !== next) {
      this[`_${attr}`] = next;
      this.render();
    }
  }

  render() {
    /* istanbul ignore next */
    if (!this.ownerDocument.defaultView) return;
  }
}

export class TabContentItem extends HTMLElement {
  constructor() {
    super();
    this._target = null;
    this.toggleHiddenWhenInactive();
  }

  toggleActiveTabByTarget(target) {
    if (this._target === target) {
      this.classList.add("active");
    } else {
      this.classList.remove("active");
    }
  }

  toggleHiddenWhenInactive() {
    if (this.classList.contains("active")) {
      this.classList.remove("hidden");
    } else {
      this.classList.add("hidden");
    }
  }

  connectedCallback() {
    this.render();
  }

  static get observedAttributes() {
    return ["target", "content", "class"];
  }

  attributeChangedCallback(attr, prev, next) {
    if (prev !== next) {
      this[`_${attr}`] = next;
      this.render();
    }
  }

  render() {
    /* istanbul ignore next */
    if (!this.ownerDocument.defaultView) return;
  }
}

if (!customElements.get("tabs-component")) {
  customElements.define("tabs-component", TabsComponent);
}
if (!customElements.get("tab-nav-item")) {
  customElements.define("tab-nav-item", TabNavigationItem);
}
if (!customElements.get("tab-content-item")) {
  customElements.define("tab-content-item", TabContentItem);
}

if (window && !window.TabsComponent) {
  window.TabsComponent = TabsComponent;
  window.TabNavigationItem = TabNavigationItem;
  window.TabContentItem = TabContentItem;
}
