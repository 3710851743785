const focusableSelectors =
  'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';

const isCollapsibleToggle = (el) =>
  el.dataset.collapsibleToggle && el.dataset.collapsibleToggle.length > 0;

const closeCollapsible = (collapsible) => collapsible.classList.add("collapse");

const openCollapsible = (collapsible) => {
  collapsible.classList.remove("collapse");
  collapsible.querySelector(focusableSelectors).focus();
};

const isCollapsibleOpen = (collapsible) =>
  collapsible.classList.contains("collapse");

const toggleCollapsible = (collapsibleId) => {
  const collapsible = document.getElementById(collapsibleId);
  isCollapsibleOpen(collapsible)
    ? openCollapsible(collapsible)
    : closeCollapsible(collapsible);
};

document.addEventListener("click", (event) => {
  let el = event.target;
  let found = false;

  while (el && !(found = isCollapsibleToggle(el))) {
    el = el.parentElement;
  }

  if (found) {
    event.preventDefault();

    toggleCollapsible(el.dataset.collapsibleToggle);
  }
});
