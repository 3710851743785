import VideoSession from "../video/session";
import VideoFullScreen from "../video/full_screen";
import HlsPlayer from "../video/hls_player";

let Hooks = {};
let producerSession = new VideoSession();
let audienceSession = new VideoSession();

let hlsPlayer;

const config = {
  showControls: false,
  width: "100%",
  height: "100%",
};

Hooks.InitProducerVideoSession = {
  mounted() {
    const publisherConfig = {
      ...config,
      publishVideo: false,
      publishAudio: false,
      audioBitRate: 64000,
      noiseSuppression: false,
      echoCancellation: false,
      disableAudioProcessing: true,
      autoGainControl: false,
      audioFallbackEnabled: false,
      frameRate: 30,
      mirror: false,
      resolution: "1280x720",
    };
    const sessionOpts = { disablePublishing: false, logPublisherStats: true };

    producerSession.init(this, publisherConfig, sessionOpts);
  },
};

Hooks.SubscribeProducerToStream = {
  mounted() {
    const subscriberConfig = {
      ...config,
      preferredFrameRate: 1,
      preferredResolution: { width: 320, height: 240 },
    };

    const streamId = this.el.dataset.streamId;
    producerSession.subscribeToStreamId(streamId, subscriberConfig);
  },
  destroyed() {
    const streamId = this.el.dataset.streamId;
    audienceSession.unsubscribeStream(streamId);
  },
};

Hooks.InitAudienceVideoSession = {
  mounted() {
    const localData = JSON.parse(localStorage.getItem("localData")) || {
      deviceLive: true,
    };
    const deviceLive = localData["deviceLive"];
    const publisherConfig = {
      ...config,
      publishAudio: false,
      publishVideo: deviceLive,
      facingMode: "user",
    };
    const sessionOpts = {
      disablePublishing: this.el.dataset.liveSession === "false",
    };
    audienceSession.init(this, publisherConfig, sessionOpts);
  },
  updated() {
    const onStage = this.el.dataset.onStage === "true";
    const audioLocked = this.el.dataset.audioLocked === "true";
    audienceSession.setAudioMuted(!onStage);
    audienceSession.lockAudio(!onStage && audioLocked);
  },
};

Hooks.SubscribeAudienceToStream = {
  mounted() {
    const subscriberConfig = {
      ...config,
      preferredFrameRate: 30,
      preferredResolution: { width: 1280, height: 720 },
    };

    const streamId = this.el.dataset.streamId;
    audienceSession
      .subscribeToStreamId(streamId, subscriberConfig)
      .then((subscriber) => {
        audienceSession.networkMonitor.onSubscribed(subscriber);
      });
  },
  destroyed() {
    const streamId = this.el.dataset.streamId;
    audienceSession.unsubscribeStream(streamId);
  },
};

Hooks.UnmuteSubscriber = {
  mounted() {
    this.el.addEventListener("click", () => {
      el = document.querySelector("#audio_control");
      el.classList.remove("unmute");
      el.classList.add("mute");
      el.disabled = true;
      OT.unblockAudio();
    });
  },
};

Hooks.toggleFullScreen = {
  mounted() {
    new VideoFullScreen(this.el);
  },
};

Hooks.InitHLS = {
  mounted() {
    const hlsErrors = document.getElementById("hls-errors");
    hlsErrors.querySelector("button").addEventListener("click", (event) => {
      event.preventDefault();

      hlsErrors.classList.add("hidden");
      startVideo(this, hlsErrors);
    });

    startVideo(this, hlsErrors);
  },
};

function startVideo(liveView, errorEl) {
  liveView.pushEvent("get_m3u8_playback_url", {}, (reply, _ref) => {
    if (reply.m3u8_playback_url) {
      const videoElement = document.getElementById("vimeo-hls");
      const videoSrc = reply.m3u8_playback_url;

      hlsPlayer = new HlsPlayer(videoElement, videoSrc);
    } else {
      errorEl.classList.remove("hidden");
      // TODO: log to sentry?
      console.log(reply.error);
    }
  });
}

export default Hooks;
