export default class CaptionsControl {
  constructor(el, videoEl) {
    this.el = el;
    this.videoEl = videoEl;
    this.disabled = true;
    this.hasTooltip = this.el.classList.contains("has-tooltip");
    this.tooltip = this.el.querySelector(".tooltip");
    this.#addListeners();
    this.#setState();
  }

  #addListeners() {
    this.el.addEventListener("click", (event) => {
      event.preventDefault();
      this.toggleCaptions();
    });
  }

  turnOffCaptions() {
    for (var i = 0; i < this.videoEl.textTracks.length; i++) {
      this.videoEl.textTracks[i].mode = "disabled";
    }
  }

  toggleCaptions() {
    var track = this.videoEl.textTracks[0];
    if (!track) return;

    if (track.mode === "showing") {
      track.mode = "disabled";
    } else {
      track.mode = "showing";
    }
    this.disabled = !this.disabled;
    this.#setState();
  }

  #setState() {
    this.el.classList.remove("on", "off");
    this.el.classList.add(this.disabled ? "off" : "on");
    this.el.ariaPressed = this.disabled ? "off" : "on";
    if (this.hasTooltip) {
      this.tooltip.textContent = this.disabled
        ? this.el.dataset.tooltipOn
        : this.el.dataset.tooltipOff;

      this.el.ariaLabel = this.tooltip.textContent;
    }
  }
}
