function getStats(resource) {
  return new Promise((resolve, reject) => {
    if (!resource || !resource.getStats) {
      reject({ name: "GET_STATS_NOT_SUPPORTED" });
    }

    resource.getStats((error, stats) => {
      if (error) {
        reject(error);
      } else {
        const { stats: callStats = stats } = [stats].flat()[0] || {};
        resolve(callStats);
      }
    });
  });
}

export default { getStats };
