import VideoHooks from "./video_hooks.js";
import ChatHooks from "./chat_hooks.js";
import CueHooks from "./cue_hooks.js";
import ReactionHooks from "./reaction_hooks.js";
import AnalyticsHooks from "./analytics_hooks.js";
import PhoenixCustomEvent from "phoenix-custom-event-hook";

const Hooks = {
  ...VideoHooks,
  ...ChatHooks,
  ...CueHooks,
  ...ReactionHooks,
  ...AnalyticsHooks,
  PhoenixCustomEvent,
};

export default Hooks;
