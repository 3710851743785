detectMobile();

function isMobile() {
  const userAgent = navigator.userAgent || "";

  const isMobile = /[^a-zA-Z0-9](?:(?:IE)?Mobi(?:le)?)[^a-zA-Z0-9]/gi.test(
    userAgent
  );

  const isIPad = /[^a-zA-Z0-9](?:(?:IE)?i(?:pad)?)[^a-zA-Z0-9]/gi.test(
    userAgent
  );

  const isIPod = /[^a-zA-Z0-9](?:(?:IE)?i(?:pod)?)[^a-zA-Z0-9]/gi.test(
    userAgent
  );

  const isIPhone = /[^a-zA-Z0-9](?:(?:IE)?i(?:phone)?)[^a-zA-Z0-9]/gi.test(
    userAgent
  );

  const isAndroid = /[^a-zA-Z0-9](?:(?:IE)?an(?:droid)?)[^a-zA-Z0-9]/gi.test(
    userAgent
  );

  var isIOS = isIPad || isIPod || isIPhone;

  const isFirefoxIOS = /[^a-zA-Z0-9](?:(?:IE)?fx(?:ios)?)[^a-zA-Z0-9]/gi.test(
    userAgent
  );

  if (isMobile || isFirefoxIOS || isIOS || isAndroid) {
    return true;
  }

  return false;
}

function whichBrowser() {
  let browser = "";
  const userAgent = navigator.userAgent || "";

  const isChrome = /[^a-zA-Z0-9](?:(?:IE)?chro(?:me)?)[^a-zA-Z0-9]/gi.test(
    userAgent
  );
  const isSafari = /[^a-zA-Z0-9](?:(?:IE)?safa(?:ri)?)[^a-zA-Z0-9]/gi.test(
    userAgent
  );

  const isFirefox = /[^a-zA-Z0-9](?:(?:IE)?fire(?:fox)?)[^a-zA-Z0-9]/gi.test(
    userAgent
  );

  const isFirefoxIOS = /[^a-zA-Z0-9](?:(?:IE)?fx(?:ios)?)[^a-zA-Z0-9]/gi.test(
    userAgent
  );

  const isMacOS = /[^a-zA-Z0-9](?:(?:IE)?mac(?:os)?)[^a-zA-Z0-9]/gi.test(
    userAgent
  );

  const isMobile = /[^a-zA-Z0-9](?:(?:IE)?Mobi(?:le)?)[^a-zA-Z0-9]/gi.test(
    userAgent
  );

  if (isFirefox || isFirefoxIOS) {
    return "firefox";
  }

  if (isSafari & !isChrome) {
    return "safari";
  }

  if (isSafari & isChrome & isMobile) {
    return "safari";
  }

  if (isSafari & isChrome) {
    return "chrome";
  }
}

function whichOS() {
  const userAgent = navigator.userAgent || "";
  const isMacOS = /[^a-zA-Z0-9](?:(?:IE)?mac(?:os)?)[^a-zA-Z0-9]/gi.test(
    userAgent
  );

  const isIPad = /[^a-zA-Z0-9](?:(?:IE)?i(?:pad)?)[^a-zA-Z0-9]/gi.test(
    userAgent
  );

  const isIPod = /[^a-zA-Z0-9](?:(?:IE)?i(?:pod)?)[^a-zA-Z0-9]/gi.test(
    userAgent
  );

  const isIPhone = /[^a-zA-Z0-9](?:(?:IE)?i(?:phone)?)[^a-zA-Z0-9]/gi.test(
    userAgent
  );

  const isAndroid = /[^a-zA-Z0-9](?:(?:IE)?an(?:droid)?)[^a-zA-Z0-9]/gi.test(
    userAgent
  );

  var isIOS = isIPad || isIPod || isIPhone;

  if (isIOS) {
    return "ios";
  }
  if (isAndroid) {
    return "android";
  }
  if (isMacOS) {
    return "mac";
  }
  return "pc";
}

function detectMobile() {
  let agentObject = {
    system: isMobile() ? "mobile" : "desktop",
    browser: whichBrowser(),
    os: whichOS(),
  };

  const rootElement = document.documentElement;
  rootElement.classList.add(agentObject.system);
  rootElement.classList.add(agentObject.browser);
  rootElement.classList.add(agentObject.os);
}
