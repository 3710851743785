const Hooks = {};

Hooks.TabsComponent = {
  mounted() {
    this.el.toggleActiveTab();
  },
  updated() {
    this.el.toggleActiveTab(this.el.getAttribute("target"));
  },
};

export default Hooks;
