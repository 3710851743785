const focusableSelectors =
  'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';

const isModalToggle = (el) =>
  el.dataset.modalToggle && el.dataset.modalToggle.length > 0;

const closeModal = (modal) => modal.classList.add("hidden");

const openModal = (modal) => {
  modal.classList.remove("hidden");
  modal.querySelector(focusableSelectors).focus();
};

const isModalOpen = (modal) => modal.classList.contains("hidden");

const toggleModal = (modalId) => {
  const modal = document.getElementById(modalId);
  isModalOpen(modal) ? openModal(modal) : closeModal(modal);
};

document.addEventListener("click", (event) => {
  let el = event.target;
  let found = false;

  while (el && !(found = isModalToggle(el))) {
    el = el.parentElement;
  }

  if (found) {
    event.preventDefault();
    toggleModal(el.dataset.modalToggle);
  }
});

document.addEventListener("keyup", (event) => {
  if (event.key === "Escape") {
    event.preventDefault();
    document
      .querySelectorAll("[data-modal]")
      .forEach((modal) => closeModal(modal));
  }
});

[...document.querySelectorAll("[data-default-open]")].forEach(openModal);
