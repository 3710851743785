import OT from "@opentok/client";

class DeviceSelect {
  constructor(publisher, containerEl, kindKey) {
    this.publisher = publisher;
    this.el = containerEl.querySelector(`[data-kind=${kindKey}]`);
    this.selectEl = this.el.querySelector(`select`);
    this.kind = `${kindKey}Input`;
    this.selectEl.addEventListener("change", this._onChange);
  }

  setDevice() {}

  getDeviceId() {}

  refreshOptions() {
    const deviceId = this.getDeviceId();
    this.selectEl.dataset.originalValue = deviceId;

    OT.getDevices((_error, devices) => {
      this._renderOptions(
        devices.filter((device) => device.kind === this.kind),
        deviceId
      );
    });
  }

  _renderOptions(devices, currentDeviceId) {
    this._clearOptions();

    if (!devices || devices.length < 1) {
      this.el.classList.add("hidden");
      return;
    }

    this.el.classList.remove("hidden");
    devices.forEach(({ label, deviceId }) => {
      const option = document.createElement("option");
      option.innerText = label;
      option.value = deviceId;
      option.selected = deviceId === currentDeviceId;
      this.selectEl.append(option);
    });
  }

  _clearOptions() {
    while (this.selectEl.firstChild) {
      this.selectEl.removeChild(this.selectEl.firstChild);
    }
  }

  _onChange = (e) => {
    const value = e.currentTarget.value;
    this.selectEl.disabled = true;
    this.setDevice(value)
      .then(() => {
        this.selectEl.dataset.originalValue = value;
      })
      .catch(() => {
        this.selectEl.value = this.selectEl.dataset.originalValue;
      })
      .finally(() => {
        this.selectEl.disabled = false;
      });
  };
}

class AudioInputDeviceSelect extends DeviceSelect {
  constructor(publisher, containerEl) {
    super(publisher, containerEl, "audio");
  }

  setDevice(deviceId) {
    return this.publisher.setAudioSource(deviceId);
  }

  getDeviceId() {
    const track = this.publisher.getAudioSource();
    const { deviceId } = (track && track.getSettings()) || {};
    return deviceId;
  }
}

class VideoInputDeviceSelect extends DeviceSelect {
  constructor(publisher, containerEl) {
    super(publisher, containerEl, "video");
  }

  setDevice(deviceId) {
    return this.publisher.setVideoSource(deviceId);
  }

  getDeviceId() {
    const { deviceId } = this.publisher.getVideoSource() || {};
    return deviceId;
  }
}

class AudioOutputDeviceSelect extends DeviceSelect {
  constructor(containerEl) {
    super(null, containerEl, "audiooutput");
  }

  setDevice(deviceId) {
    return OT.setAudioOutputDevice(deviceId);
  }

  refreshOptions() {
    OT.getActiveAudioOutputDevice().then((currentDevice) => {
      this.selectEl.dataset.originalValue = currentDevice.deviceId;

      OT.getAudioOutputDevices().then((devices) => {
        this._renderOptions(devices, currentDevice.deviceId);
      });
    });
  }
}

export default class DeviceSettings {
  constructor(publisher, elId) {
    const el = document.getElementById(elId);
    if (!el) {
      return;
    }

    this.audioInputSelect = new AudioInputDeviceSelect(publisher, el);
    this.videoInputSelect = new VideoInputDeviceSelect(publisher, el);
    this.audioOutputSelect = new AudioOutputDeviceSelect(el);
    navigator.mediaDevices.addEventListener(
      "devicechange",
      this.refreshDeviceOptions
    );
  }

  init() {
    this.refreshDeviceOptions();
  }

  refreshDeviceOptions = () => {
    [this.audioInputSelect, this.videoInputSelect, this.audioOutputSelect]
      .filter((x) => x)
      .forEach((deviceSelect) => deviceSelect.refreshOptions());
  };
}
