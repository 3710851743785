export default class ContainerScroll {
  chatScrollAtBottom = true;
  previousScrollTop = null;

  constructor() {
    this.chatScrollEl = document.getElementById("chat_scroll");
    this.scrollToBottom();
  }

  beforeUpdate() {
    this.chatScrollAtBottom = false;
    this.previousScrollTop = this.chatScrollEl.scrollTop;
    if (
      this.chatScrollEl.scrollTop + this.chatScrollEl.offsetHeight ===
      this.chatScrollEl.scrollHeight
    ) {
      this.chatScrollAtBottom = true;
    }
  }
  updated() {
    if (this.chatScrollAtBottom) {
      this.scrollToBottom();
    } else {
      this.chatScrollEl.scrollTop = this.previousScrollTop;
    }
  }

  scrollToBottom() {
    this.chatScrollEl.scrollTop =
      this.chatScrollEl.scrollHeight - this.chatScrollEl.offsetHeight;
  }
}
