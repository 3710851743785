export default class MessageContainer {
  constructor(containerEl, userId) {
    this.containerEl = containerEl;
    this.userId = userId;
  }

  append(message) {
    const el = document.createElement("div");
    const { id: userId, name, avatar_url } = message.user;
    const isSelf = this.userId == userId;
    el.id = `message_${message.id}`;
    el.className = "relative px-4 pb-2 align-middle message-body";
    el.dataset["self"] = isSelf;

    const avatarEl = document.createElement("avatar-component");
    avatarEl.setAttribute("name", name);
    avatarEl.setAttribute("id-string", userId);
    if (avatar_url) avatarEl.setAttribute("href", avatar_url);
    avatarEl.classList.add("icon-6");

    const nameEl = document.createElement("span");
    nameEl.innerText = `${name}: `;
    nameEl.className = `font-bold ${isSelf ? "text-red-700" : ""}`;

    const wrapperEl = document.createElement("span");
    wrapperEl.className = "flex gap-1.5 float-left";
    wrapperEl.append(avatarEl);
    wrapperEl.append(nameEl);
    el.append(wrapperEl);

    const textEl = document.createElement("span");
    textEl.className = "ml-1 break-words whitespace-pre-wrap";
    el.append(textEl);
    textEl.innerText = message.text;

    this.containerEl.append(el);
  }

  clear() {
    this.containerEl.innerHTML = "";
  }

  delete(messageId) {
    const elm = this.containerEl.querySelector(`#message_${messageId}`);
    if (elm) {
      elm.remove();
    }
  }
}
