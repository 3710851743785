import Hls, { isSupported } from "hls.js";
import VolumeControl from "./volume_control.js";
import CaptionsControl from "./captions_controls.js";
import PlayControl from "./play_control.js";
import * as Sentry from "@sentry/browser";

export default class HlsPlayer {
  constructor(videoElement, videoSrc) {
    this.videoElement = videoElement;

    this.ccButton = new CaptionsControl(
      document.getElementById("toggle-closed-captions"),
      this.videoElement
    );

    this.volumeControl = new VolumeControl(
      document.getElementById("volume-controls"),
      this.videoElement
    );

    this.playControl = new PlayControl(
      document.getElementById("play-control"),
      this.videoElement
    );

    this.videoElement.addEventListener("canplaythrough", (event) => {
      const { target } = event;
      if (target.paused) {
        if (this.videoElement.readyState >= 3) {
          target.play().catch((error) => {
            this.playControl.show();
            logEvent(event, error);
          });
        } else {
          this.playControl.show();
        }
      }
      this.ccButton.turnOffCaptions();
    });

    if (isSupported()) {
      this.hls = new Hls();

      this.videoElement.muted = false;

      // This should send to server so we can report to segment
      this.hls.on(Hls.Events.FPS_DROP, logEvent);

      // this is probably for the logs, maybe report if the user is forced to step down to lowest levels
      this.hls.on(Hls.Events.LEVEL_SWITCHED, logEvent);

      this.hls.on(Hls.Events.ERROR, function (event, data) {
        logEvent(event, data);

        if (data.fatal) {
          switch (data.type) {
            case Hls.ErrorTypes.MEDIA_ERROR:
              this.hls.recoverMediaError();
              break;
          }
        }
      });

      this.hls.loadSource(videoSrc);
      this.hls.attachMedia(videoElement);
    } else if (this.videoElement.canPlayType("application/vnd.apple.mpegurl")) {
      console.log("No HLS.js support, switching to native <video>");

      this.videoElement.querySelector("#video-source").src = videoSrc;
      this.videoElement.load();
    } else {
      alert("Your browser does not support Studio Workshops");
      return;
    }
  }
}

function logEvent(event, data) {
  console.log(event, data);
  Sentry.withScope((scope) => {
    scope.setExtra("statsSnapshot", data);
    Sentry.captureMessage(`HSL error ${event}`);
  });
}
