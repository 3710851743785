window.addEventListener("load", function (e) {
  [...document.querySelectorAll("[data-date]")].forEach((el) => {
    const datetime = new Date(parseInt(el.dataset.date, 10));
    const options = {
      weekday: "long",
      year: "numeric",
      month: "numeric",
      day: "numeric",
    };
    el.textContent = datetime.toLocaleDateString(undefined, options);
  });

  [...document.querySelectorAll("[data-time]")].forEach((el) => {
    const datetime = new Date(parseInt(el.dataset.time, 10));
    el.textContent = datetime.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });
  });

  document.querySelectorAll("[data-time-numeric]").forEach((el) => {
    const datetime = new Date(parseInt(el.dataset.timeNumeric, 10));
    el.textContent = datetime.toLocaleTimeString([], {
      hour12: true,
      hour: "numeric",
      minute: "numeric",
    });
  });
});
