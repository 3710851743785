import pdfform from "pdfform.js/pdfform.js";
import Analytics from "./analytics";
import * as Sentry from "@sentry/browser";

document.addEventListener("submit", (event) => {
  const form = event.target;

  if (form.dataset.pdfFilename) {
    event.preventDefault();
    saveToPDF(form);
  }
});

function saveToPDF(form) {
  const { pdfFilename: filename, pdfSourceUrl: sourceURL } = form.dataset;

  getPDFArrayBuffer(sourceURL).then((buffer) => {
    const fields = getFormFields(form);
    console.debug("Form fields:", fields);
    console.debug("PDF fields:", pdfform().list_fields(buffer));
    saveAs(pdfform().transform(buffer, fields), filename);
    trackDownloadEvent(fields);
  });
}

function getPDFArrayBuffer(sourceURL) {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();

    xhr.onload = function () {
      try {
        if (this.status !== 200) {
          throw new Error(
            `Error retrieving ${sourceURL}. Status ${this.status}.`
          );
        }

        const blob = new Blob([xhr.response], { type: "application/pdf" });
        blob.arrayBuffer().then((buf) => resolve(buf));
      } catch (e) {
        reject(e);
      }
    };

    xhr.open("GET", sourceURL, true);
    xhr.setRequestHeader("Content-type", "application/pdf");
    xhr.responseType = "blob";
    xhr.send(null);
  });
}

function getFormFields(form) {
  const fields = {};

  form.querySelectorAll("[data-pdf-field]").forEach((field) => {
    fields[field.dataset.pdfField] = [field.value];
  });

  return fields;
}

function saveAs(pdf, filename) {
  const blob = new Blob([pdf], { type: "application/pdf" });
  const url = window.URL.createObjectURL(blob);
  const anchor = document.createElement("a");
  anchor.classList.add("hidden");
  anchor.href = url;
  anchor.download = filename;
  anchor.click();
  anchor.remove();
  window.URL.revokeObjectURL(blob);
}

function trackDownloadEvent(fields) {
  try {
    const keys = Object.keys(fields);
    const completedFields = keys.filter(
      (key) => fields[key] && !!fields[key][0]
    );
    const completed = Math.round((completedFields.length * 100) / keys.length);
    Analytics.trackEvent("Worksheet Downloaded", {
      percent_completed: completed,
    });
  } catch (e) {
    console.error(e);
    Sentry.captureException(e);
  }
}

// Worksheet Form Input listener for analytics tracking
document.addEventListener("focusout", (event) => {
  try {
    const el = event.target;

    if (el.form && el.form.dataset.pdfFilename && (el.value || {}).length) {
      Analytics.trackEvent("Worksheet Updated", { worksheet_field: el.name });
    }
  } catch (e) {
    console.error(e);
    Sentry.captureException(e);
  }
});
