export default class VideoFullScreen {
  constructor(element) {
    this.el = element;
    this.stage = document.querySelector("#main-stage");
    this.tooltip = this.el.querySelector(".tooltip");
    this.tooltip.textContent = this.el.dataset.tooltipEnter;
    this._addListener();
  }

  _addListener() {
    this.el.addEventListener("click", () => {
      if (this.el.classList.contains("exit")) {
        this.tooltip.textContent = this.el.dataset.tooltipEnter;
        this.el.classList.remove("exit");
        this._exitFullscreen();
      } else {
        this.tooltip.textContent = this.el.dataset.tooltipExit;
        this.el.classList.add("exit");
        this._launchFullscreen();
      }
    });
  }

  _launchFullscreen() {
    if (this.stage.requestFullscreen) {
      this.stage.requestFullscreen();
    } else if (this.stage.mozRequestFullScreen) {
      this.stage.mozRequestFullScreen();
    } else if (this.stage.webkitRequestFullscreen) {
      this.stage.webkitRequestFullscreen();
    }
  }
  _exitFullscreen() {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    }
  }
}
