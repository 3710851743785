import ChatContainerScroll from "../chat/container_scroll";
import ChatMessageContainer from "../chat/message_container";
import EmojiSetup from "../chat/emoji_picker";

const Hooks = {};

let chatContainerScroll;

Hooks.ChatForm = {
  chatScrollEl: document.getElementById("chat_scroll"),
  mounted() {
    EmojiSetup(
      document.getElementById("emoji-trigger"),
      document.getElementById("chat-message")
    );
    this.el.addEventListener("submit", (event) => {
      event.preventDefault();
      const button = this.el.querySelector("button[type=submit]");
      const input = this.el.querySelector("input");
      const message = input.value;

      if (button.disabled || message.length < 1) {
        return;
      }

      chatContainerScroll.scrollToBottom();

      button.disabled = true;
      this.pushEvent("add_message", { message }, (_reply, _ref) => {
        button.disabled = false;
      });

      input.value = "";
    });
  },
  updated() {
    this.chatScrollEl.scrollTop =
      this.chatScrollEl.scrollHeight - this.chatScrollEl.offsetHeight;
  },
};

Hooks.ConversationObserveAndUpdate = {
  mounted() {
    chatContainerScroll = new ChatContainerScroll();
  },
  beforeUpdate() {
    chatContainerScroll.beforeUpdate();
  },
  updated() {
    chatContainerScroll.updated();
  },
};

Hooks.AudienceChat = {
  mounted() {
    chatContainerScroll = new ChatContainerScroll();
    const chatMessageContainer = new ChatMessageContainer(
      document.getElementById("chat_scroll"),
      this.el.dataset["userId"]
    );

    this.handleEvent("message_added", function ({ message }) {
      chatContainerScroll.beforeUpdate();
      chatMessageContainer.append(message);
      chatContainerScroll.updated();
    });

    this.handleEvent("clear_room", function () {
      chatMessageContainer.clear();
      chatContainerScroll.updated();
    });

    this.handleEvent("delete_message", function ({ message_id }) {
      chatMessageContainer.delete(message_id);
      chatContainerScroll.updated();
    });
  },
};

Hooks.DismissAvatarWarning = {
  mounted() {
    this.el.addEventListener("click", () => {
      const el = document.querySelector("#avatar-warning");
      el.classList.add("hidden");
    });
  },
};
export default Hooks;
