/* istanbul ignore file */
class PressEmbargoComponent extends HTMLElement {
  constructor() {
    super();
    this.trackingInterval = null;
    this.updateContent = true;
  }
  connectedCallback() {
    if (!this.hasAttribute("starts-at")) {
      console.info("Press Embargo: starts-at set to Date.now()");
      this.startsAt = this.getCurrentTimeInt();
    }
    if (!this.hasAttribute("durration-minutes")) {
      this.durrationMinutes = 5;
    }
    if (!this.hasAttribute("buffer-minutes")) {
      this.bufferMinutes = 5;
    }
    if (!this.hasAttribute("ends-at")) {
      this.endsAt = this.startsAt + this.getDurrationInt();
    }
    this.init();
    this.classList.remove("hidden");
  }
  init() {
    this.update();
    this.startTracking();
  }

  startTracking() {
    this.trackingInterval = setInterval(() => {
      this.update();
      if (this.hasLongEnded) {
        this.stopTracking();
      }
    }, 1000);
  }

  stopTracking() {
    clearInterval(this.trackingInterval);
    this.trackingInterval = null;
  }

  getIsDebugging() {
    return this.hasAttribute("debug") ? true : false;
  }

  update() {
    this.querySelectorAll("press-embargo-item").forEach((child) => {
      child.classList.add("hidden");
    });
    this.isDebugging = this.getIsDebugging();
    this.startsAtInt = this.startsAt;
    this.startsAtDate = new Date(parseInt(this.startsAt, 10));
    this.endsAtDate = this.getEndsAtDate();
    this.endsAtInt = this.endsAt;
    this.hasEnded = this.getHasEnded();
    this.willHappen = this.getWillHappen();
    this.willStartSoon = this.getWillStartSoon();
    this.isHappening = this.getIsHappening();
    this.hasRecentlyEnded = this.getHasRecentlyEnded();
    this.hasLongEnded = this.getHasLongEnded();
    this.currentTimeInt = this.getCurrentTimeInt();
    this.currentDate = this.getCurrentDate();
    this.durration = this.durrationMinutes;
    this.buffer = this.bufferMinutes;
    this.endsInStr = this.getEndsInStr();
    this.startsInStr = this.getStartsInStr();

    this.showContent();
  }

  showContent() {
    const currentContentReference = this.getContentReference();
    if (
      currentContentReference &&
      this.querySelector(
        `press-embargo-item[content='${currentContentReference}']`
      )
    ) {
      this.querySelector(
        `press-embargo-item[content='${currentContentReference}']`
      ).classList.toggle("hidden");
    }
    this.querySelectorAll("press-embargo-item timer").forEach((child) => {
      child.textContent = `${this.startsInStr}`;
    });
    if (this.isDebugging) {
      this.startsIn = this.startsInStr;
      this.endsIn = this.endsInStr;
    }
  }

  getContentReference() {
    if (this.willStartSoon) {
      return "will-start-soon";
    }
    if (this.isHappening) {
      return "has-started";
    }
    if (this.hasRecentlyEnded) {
      return "recently-ended";
    }
    if (this.willHappen) {
      return "will-happen";
    }
    if (this.hasLongEnded) {
      return "has-long-ended";
    }
    return;
  }

  set startsIn(newValue) {
    this.setAttribute("starts-in", newValue);
  }

  set endsIn(newValue) {
    this.setAttribute("ends-in", newValue);
  }

  get bufferMinutes() {
    return parseInt(this.getAttribute("buffer-minutes"));
  }

  set bufferMinutes(newValue) {
    this.setAttribute("buffer-minutes", newValue);
  }

  getBufferInt() {
    return this.bufferMinutes * 60000;
  }

  get durrationMinutes() {
    return parseInt(this.getAttribute("durration-minutes"));
  }

  set durrationMinutes(newValue) {
    this.setAttribute("durration-minutes", newValue);
  }

  getDurrationInt() {
    return this.durrationMinutes * 60000;
  }

  get startsAt() {
    return parseInt(this.getAttribute("starts-at"), 10);
  }

  set startsAt(newValue) {
    this.setAttribute("starts-at", newValue);
  }

  getStartsAtDate() {
    return new Date(this.startsAt);
  }

  get endsAt() {
    return parseInt(this.getAttribute("ends-at"), 10);
  }

  set endsAt(newValue) {
    this.setAttribute("ends-at", newValue);
  }

  getEndsAtDate() {
    return new Date(this.endsAt);
  }

  getWillHappen() {
    return this.getCurrentTimeInt() <= this.startsAt;
  }

  getStartsIn() {
    return this.startsAt - this.getCurrentTimeInt();
  }

  getStartsInStr() {
    return this.convertMsToTime(this.getStartsIn());
  }

  getEndsIn() {
    return this.endsAt - this.getCurrentTimeInt();
  }

  getEndsInStr() {
    return this.convertMsToTime(this.getEndsIn());
  }

  getWillStartSoon() {
    return (
      this.getCurrentTimeInt() <= this.startsAt &&
      this.getCurrentTimeInt() >= this.startsAt - this.getBufferInt()
    );
  }

  getIsHappening() {
    return (
      this.getCurrentTimeInt() >= this.startsAt &&
      this.getCurrentTimeInt() <= this.endsAt
    );
  }

  getHasLongEnded() {
    return this.getHasEnded() && !this.getHasRecentlyEnded();
  }

  getHasHappened() {
    return this.getCurrentTimeInt() >= this.endsAt;
  }

  getHasEnded() {
    return this.getCurrentTimeInt() >= this.endsAt;
  }

  getHasRecentlyEnded() {
    const tenMinutesAfterEnd = this.endsAt + this.getBufferInt();
    return this.getHasEnded() && this.getCurrentTimeInt() <= tenMinutesAfterEnd;
  }

  getCurrentTimeInt() {
    return new Date().getTime();
  }

  getCurrentDate() {
    return new Date();
  }

  padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }

  convertMsToTime(milliseconds) {
    let negativeStr = "";
    if (milliseconds < 0) {
      milliseconds *= -1;
      negativeStr = "-";
    }
    let seconds = Math.floor(milliseconds / 1000);
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);

    seconds = seconds % 60;
    minutes = minutes % 60;

    if (hours === 0) {
      return `${negativeStr}${this.padTo2Digits(minutes)}:${this.padTo2Digits(
        seconds
      )}`;
    }
    return `${negativeStr}${this.padTo2Digits(hours)}:${this.padTo2Digits(
      minutes
    )}:${this.padTo2Digits(seconds)}`;
  }
}

customElements.define("press-embargo-component", PressEmbargoComponent);
