import * as Sentry from "@sentry/browser";
import OpenTok from "./opentok";

const LOG_IGNORE_LIST = [
  "OT_PEER_CONNECTION_NOT_CONNECTED",
  "GET_STATS_NOT_SUPPORTED",
];
const THRESHOLDS = {
  audio: {
    kbps: 25,
    packetLossRatio: 0.05,
  },
  video: {
    kbps: 200,
    packetLossRatio: 0.03,
  },
};

export default class TokboxNetworkMonitor {
  hasAcceptableQuality = true;
  _previousStats = null;
  _publisher = null;
  _subscriber = null;
  _lastNetworkSnapshot = null;
  _networkQualityPollingIntervalMs = 1_000 * 60;
  _hasReportedToSentry = false;

  constructor({ liveview, logPublisher }) {
    this.liveview = liveview;
    this._logPublisher = logPublisher;
  }

  onPublished(publisher) {
    this._publisher = publisher;
    if (this._logPublisher) {
      this._scheduleNetworkQualityCheck();
    }
    publisher.on("destroyed", () => {
      this._publisher = null;
    });
  }

  onSubscribed(subscriber) {
    this._subscriber = subscriber;
    this._scheduleNetworkQualityCheck();
    subscriber.on("destroyed", () => {
      this._subscriber = null;
    });
  }

  _scheduleNetworkQualityCheck() {
    if (
      !this.nextNetworkQualityCheck &&
      (this._publisher || this._subscriber)
    ) {
      this.nextNetworkQualityCheck = setTimeout(() => {
        this.nextNetworkQualityCheck = null;
        this._scheduleNetworkQualityCheck();
        const networkStats = this._getNetworkStats();
        networkStats.then(this._checkNetworkQuality.bind(this));
      }, this._networkQualityPollingIntervalMs);
    }
  }

  _checkNetworkQuality([publisherStats, subscriberStats]) {
    const publisherStatsSnapshot = this._statsDiff(
      this._previousPublisherStats,
      publisherStats
    );
    const subscriberStatsSnapshot = this._statsDiff(
      this._previousSubscriberStats,
      subscriberStats
    );
    this._previousPublisherStats = publisherStats;
    this._previousSubscriberStats = subscriberStats;
    if (publisherStatsSnapshot && this._logPublisher) {
      this._updateHasAcceptableQuality(
        "publisher",
        publisherStatsSnapshot,
        this._publisher
      );
    }
    if (subscriberStatsSnapshot) {
      this._updateHasAcceptableQuality(
        "subscriber",
        subscriberStatsSnapshot,
        this._subscriber
      );
    }
  }

  _getNetworkStats() {
    const statsPromises = [
      this.resourceStats("publisher", this._publisher),
      this.resourceStats("subscriber", this._subscriber),
    ];
    return Promise.all(statsPromises).catch((error) => {
      Sentry.captureException(error);
    });
  }

  _checkQualityThreshold({ statsSnapshot, hasAudio, hasVideo }) {
    if (!hasAudio && !hasVideo) {
      return true;
    }
    const statsType = hasVideo ? "video" : "audio";
    const qualityThresholds = THRESHOLDS[statsType];
    const stats = statsSnapshot[statsType];
    if (!stats) {
      return true;
    }
    return (
      stats.kbps >= qualityThresholds.kbps &&
      stats.packetLossRatio <= qualityThresholds.packetLossRatio
    );
  }

  _updateHasAcceptableQuality(type, statsSnapshot, channel) {
    let meetsQualityThreshold = true;
    let poorConnectionIcon = document.getElementById("poor-connection-icon");
    if (channel && channel.stream) {
      const stream = channel.stream;
      meetsQualityThreshold = this._checkQualityThreshold({
        statsSnapshot: statsSnapshot,
        hasAudio: stream.hasAudio,
        hasVideo: stream.hasVideo,
      });
      if (this.hasAcceptableQuality && !meetsQualityThreshold) {
        if (type === "subscriber")
          poorConnectionIcon.classList.remove("hidden");
        if (!this._hasReportedToSentry) {
          Sentry.withScope((scope) => {
            scope.setExtra("statsSnapshot", statsSnapshot);
            Sentry.captureMessage("Network quality dropped");
          });
          this._hasReportedToSentry = true;
        }
      } else if (
        !this.hasAcceptableQuality &&
        meetsQualityThreshold &&
        type === "subscriber"
      ) {
        poorConnectionIcon.classList.add("hidden");
      }
    }
    this.hasAcceptableQuality = meetsQualityThreshold;
  }

  _streamStats(stream, statsSnapshot) {
    const audio = statsSnapshot.audio || {};
    const video = statsSnapshot.video || {};
    return {
      audio: {
        enabled: stream.hasAudio,
        kbps: audio.kbps,
        packetLossRatio: audio.packetLossRatio,
      },
      video: {
        enabled: stream.hasVideo,
        kbps: video.kbps,
        packetLossRatio: video.packetLossRatio,
        dimensions: stream.videoDimensions,
        type: stream.videoType,
      },
    };
  }

  _statsDiff(previousStats, currentStats) {
    if (!previousStats || !currentStats) {
      return null;
    }
    const elapsedMs = currentStats.timestamp - previousStats.timestamp;
    const results = {};
    ["audio", "video"].forEach((type) => {
      const previous = previousStats[type];
      const current = currentStats[type];
      if (!previous || !current) {
        return;
      }

      const bytesAttempted =
        (current.bytesReceived || current.bytesSent || 0) -
        (previous.bytesReceived || previous.bytesSent || 0);
      const packetsDelivered =
        (current.packetsReceived || current.packetsSent || 0) -
        (previous.packetsReceived || previous.packetsSent || 0);
      const packetsLost = current.packetsLost - previous.packetsLost;
      const totalPackets = packetsDelivered + packetsLost;
      const kbps = (bytesAttempted * 8) / elapsedMs;
      const packetLossRatio =
        totalPackets <= 0 ? 0 : packetsLost / totalPackets;

      results[type] = {
        kbps: Math.max(Math.round(kbps), 0),
        packetLossRatio: +packetLossRatio.toFixed(4),
      };
    });
    return results;
  }

  _logInfo(message, data) {
    this.liveview.pushEvent(
      "log_info",
      { message, data: JSON.stringify(data) },
      function () {}
    );
    console.debug(message, data);
  }

  resourceStats(type, resource) {
    return OpenTok.getStats(resource)
      .then((stats) => {
        this._logInfo(`${type}_call_stats`, stats);
        return stats;
      })
      .catch(({ code, message, name }) => {
        if (!LOG_IGNORE_LIST.includes(name)) {
          this._logInfo(`${type}_call_stats`, { code, message, name });
        }
      });
  }
}
