/*
  Usage:

  <avatar-component class="icon-16"/>
  <avatar-component name="Mel" class="icon-6"/>
  <avatar-component name="Terri" id-string={@user.id} class="icon-5"/>
  <avatar-component name="Pat" class="icon-24" href="https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80"/>

  The icon classes icon-5, icon-6, icon-16, icon-24 set:
  - font-size
  - line-height
  - width
  - height

  */
export class AvatarComponent extends HTMLElement {
  constructor() {
    super();
    this._idString = this.idString;
  }

  connectedCallback() {
    this.init();
  }

  static get observedAttributes() {
    return ["href", "name", "style", "id-string"];
  }

  attributeChangedCallback(attr, prev, next) {
    if (prev !== next) {
      this[`_${attr}`] = next;
      this.render();
    }
  }

  get href() {
    return this.getAttribute("href");
  }

  get name() {
    return this.getAttribute("name") || "*";
  }

  get idString() {
    return this.getAttribute("id-string");
  }

  get initialLetter() {
    return this.name.toUpperCase().charAt(0);
  }

  get avatarClass() {
    const str = this.idString || (Math.random() * 10000).toString();

    let i,
      l,
      hval = 0xabcd1234;

    for (i = 0, l = str.length; i < l; i++) {
      hval ^= str.charCodeAt(i);
      hval +=
        (hval << 1) + (hval << 4) + (hval << 7) + (hval << 8) + (hval << 24);
    }

    const nval = hval >>> 0;

    // Custom backgrounds have to be loaded.
    ("bg-avatar-0 bg-avatar-1 bg-avatar-2 bg-avatar-3 bg-avatar-4 bg-avatar-5 bg-avatar-6 bg-avatar-7");

    const avatar = nval % 8;
    const color = [4, 7].includes(avatar) ? "text-black" : "text-white";
    return [`bg-avatar-${avatar}`, color];
  }

  removeAvatarClass(startingStr) {
    for (let i = this.classList.length - 1; i >= 0; i--) {
      const className = this.classList[i];
      if (className.startsWith(startingStr)) {
        this.classList.remove(className);
      }
    }
  }

  loadAvatarImage() {
    this.getImage()
      .then((url) => {
        this.style.backgroundImage = `url(${url})`;
        const childTag = this.querySelector("span");
        childTag.style.display = "none";
      })
      .catch((error) => {
        console.error(error);
      });
  }

  init() {
    this.classList.add(
      "flex",
      "flex-col",
      "items-center",
      "justify-center",
      "bg-cover",
      "rounded-full"
    );
  }

  render() {
    this.removeAvatarClass("text-");
    this.removeAvatarClass("bg-avatar-");
    const [avatar, color] = this.avatarClass;
    this.classList.add(avatar, color);
    if (!this.idString && !this.href) return null;
    if (this.children.length) return null;
    const span = document.createElement("span");
    span.appendChild(document.createTextNode(this.initialLetter));
    this.appendChild(span);
    if (this.href) {
      this.loadAvatarImage();
    }
  }

  getImage() {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = this.href;
      img.onload = () => resolve(this.href);
      img.onerror = () => {
        reject(`Image not found for url ${this.href}`);
      };
    });
  }
}

if (!customElements.get("avatar-component")) {
  customElements.define("avatar-component", AvatarComponent);
}

if (window && !window.AvatarComponent) {
  window.AvatarComponent = AvatarComponent;
}
