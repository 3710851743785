const Hooks = {};

class Reaction {
  constructor(containerEl, reaction, mine) {
    this.containerEl = containerEl;
    this.reaction = reaction;
    this.mine = mine;
  }

  getRandomAnimVarsClass = () => {
    const animVarsClasses = [
      "anim-vars-1",
      "anim-vars-2",
      "anim-vars-3",
      "anim-vars-4",
      "anim-vars-5",
    ];
    const selectedClass =
      animVarsClasses[Math.floor(Math.random() * animVarsClasses.length)];
    return selectedClass;
  };

  render() {
    const el = document.createElement("div");
    el.className = `${this.getRandomAnimVarsClass()} ${
      this.mine ? "my-reaction" : "default-reaction"
    } anim anim-sway-float-fade`;
    const innerEl = document.createElement("div");
    innerEl.innerText = this.reaction;
    innerEl.className = "text-4xl self-end";
    el.append(innerEl);
    this.containerEl.append(el);

    setTimeout(() => {
      el.remove();
    }, 3500);
  }
}

Hooks.ReactionButton = {
  mounted() {
    this.handleEvent("reaction_added", function ({ reaction, mine }) {
      new Reaction(
        document.getElementById("reaction_container"),
        reaction,
        mine
      ).render();
    });
  },
};

export default Hooks;
