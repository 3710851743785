/* istanbul ignore file */
class MediaSettingsComponent extends HTMLElement {
  constructor() {
    super();
    this.video = this.querySelector("video");
    this.camButton = this.querySelector("[is='camera-button']");
    this.micButton = this.querySelector("[is='microphone-button']");
    this.mediaLiveCheck = this.querySelector("[is='media-live-check']");
    this.volumeMeterEl = this.querySelector("[is='volume-meter-component']");
    this.stream = null;
    this.aStream = null;
  }
  connectedCallback() {
    this.getMediaDeviceLive();

    this.camButton.addEventListener("click", (ev) => {
      this.toggleCamera();
    });

    this.micButton.addEventListener("click", (ev) => {
      this.toggleMicrophone();
    });

    this.mediaLiveCheck.addEventListener("click", (ev) => {
      this.setMediaDeviceLive();
    });

    this.classList.remove("hidden");
  }

  async toggleCamera() {
    if (this.stream) {
      this.stopCamera();
      this.stopVideo();
    } else {
      await this.startCamera();
      if (this.stream) {
        this.startVideo();
      }
    }
  }

  async toggleMicrophone() {
    if (this.aStream) {
      this.stopMicrophone();
      this.stopAudio();
      // chrome will still show a permission indicator even if all streams have stopped
    } else {
      await this.startMicrophone();
      if (this.aStream) {
        this.startAudio();
      }
    }
  }

  getMediaDeviceLive() {
    const lsObj = this.localData;
    if (typeof lsObj["deviceLive"] === "undefined") {
      lsObj["deviceLive"] = true;
      this.localData = lsObj;
    }
    this.mediaLiveCheck.checked = lsObj["deviceLive"];
    return this.mediaLiveCheck.checked ? true : false;
  }

  setMediaDeviceLive() {
    const lsObj = this.localData;
    lsObj["deviceLive"] = this.mediaLiveCheck.checked ? true : false;
    this.localData = lsObj;
  }

  set localData(newValue) {
    localStorage.setItem("localData", JSON.stringify(newValue));
  }

  get localData() {
    return JSON.parse(localStorage.getItem("localData")) || {};
  }

  startVideo() {
    if (this.videoStream) {
      this.video.classList.add("playing");
      this.video.srcObject = this.videoStream;
    }
  }

  stopVideo() {
    this.video.classList.remove("playing");
    this.video.srcObject = this.videoStream;
  }

  startAudio() {
    if (this.audioStream) {
      this.volumeMeterEl.classList.add("receiving-audio");
      this.volumeMeterEl.classList.remove("hidden");
    }
  }

  stopAudio() {
    this.volumeMeterEl.classList.remove("receiving-audio");
    this.volumeMeterEl.classList.add("hidden");
  }

  videoStreamOnFrame = () => {
    if (this.videoStream) {
      window.requestAnimationFrame(this.videoStreamOnFrame);
    }
    if (this.camButton.isMuted) {
      this.stopCamera();
      this.stopVideo();
    }
  };

  async startCamera() {
    let vidStream = null;
    if (navigator.mediaDevices.getUserMedia) {
      vidStream = await navigator.mediaDevices
        .getUserMedia({ video: true, audio: false })
        .then((stream) => {
          this.videoStream = stream;
          window.requestAnimationFrame(this.videoStreamOnFrame);
          return "OK";
        })
        .catch(function (error) {
          console.warn("Video setup stream could not start");
          return "ERROR";
        });
    } else {
      console.warn("No media devices detected");
    }
    return "ERROR";
  }

  stopCamera() {
    this.videoStream = null;
  }

  audioStreamOnFrame = () => {
    if (this.audioStream) {
      this.audioAnalyserNode.getFloatTimeDomainData(this.audioPCMData);
      let sumSquares = 0.0;
      for (const amplitude of this.audioPCMData) {
        sumSquares += amplitude * amplitude;
      }
      this.volumeMeterEl.value = Math.sqrt(
        sumSquares / this.audioPCMData.length
      );
      window.requestAnimationFrame(this.audioStreamOnFrame);
    } else {
      this.volumeMeterEl.value = 0;
    }
    if (this.micButton.isMuted) {
      this.stopMicrophone();
      this.stopAudio();
    }
  };

  async startMicrophone() {
    let audStream = null;
    let audioContext = new AudioContext();
    this.audioAnalyserNode = audioContext.createAnalyser();
    this.audioPCMData = new Float32Array(this.audioAnalyserNode.fftSize);
    if (navigator.mediaDevices.getUserMedia) {
      audStream = await navigator.mediaDevices
        .getUserMedia({ video: false, audio: true })
        .then((stream) => {
          this.audioStream = stream;
          let mediaStreamAudioSourceNode = audioContext.createMediaStreamSource(
            this.audioStream
          );
          mediaStreamAudioSourceNode.connect(this.audioAnalyserNode);
          window.requestAnimationFrame(this.audioStreamOnFrame);
          return "OK";
        })
        .catch(function (error) {
          console.warn("Audio stream could not start");
          return "ERROR";
        });
    } else {
      console.warn("No media devices detected");
    }
    return "ERROR";
  }

  stopMicrophone() {
    this.audioStream = null;
    this.audioPCMData = null;
    this.audioAnalyserNode = null;
  }

  getIsDebugging() {
    return this.hasAttribute("debug") ? true : false;
  }

  set videoStream(newStream) {
    if (!newStream && this.stream) {
      let tracks = this.stream.getTracks();

      tracks.forEach(function (track) {
        track.enabled = false;
        track.stop();
      });
    }

    this.stream = newStream;
    if (this.stream) {
      this.classList.add("streaming");
    } else {
      this.classList.remove("streaming");
    }
  }

  get audioStream() {
    return this.aStream;
  }

  set audioStream(newStream) {
    if (!newStream && this.aStream) {
      let tracks = this.aStream.getTracks();
      tracks.forEach(function (track) {
        track.enabled = false;
        track.stop();
      });
    }

    this.aStream = newStream;
    if (this.aStream) {
      this.classList.add("audio-streaming");
    } else {
      this.classList.remove("audio-streaming");
    }
  }

  get videoStream() {
    return this.stream;
  }

  set videoOn(newValue) {
    this.setAttribute("video-on", newValue);
  }

  get videoOn() {
    return parseInt(this.getAttribute("video-on"));
  }

  set microphoneOn(newValue) {
    this.setAttribute("microphone-on", newValue);
  }

  get microphoneOn() {
    return parseInt(this.getAttribute("microphone-on"));
  }
}

customElements.define("media-settings-component", MediaSettingsComponent);

class avControlButtonComponent extends HTMLButtonElement {
  constructor(...args) {
    const self = super(...args);
    self.el = this;
    self.el.addEventListener("click", self.toggleMuted);
    self.hasTooltip = self.el.classList.contains("has-tooltip");
    self.tooltip = self.el.querySelector(".tooltip");
    self.init();
    return self;
  }

  init() {
    this.updateButton();
    this.classList.remove("hidden");
  }

  get isMuted() {
    return this.hasAttribute("muted");
  }

  set isMuted(newVal) {
    if (newVal) {
      this.setAttribute("muted", "");
    } else {
      this.removeAttribute("muted");
    }
  }

  mute() {
    this.setMuted(true);
  }

  unMute() {
    this.setMuted(false);
  }

  setMuted(newVal) {
    this.isMuted = newVal;
  }

  toggleMuted() {
    this.setMuted(!this.isMuted);
    this.updateButton();
  }

  setClasses() {
    this.el.classList.remove("muted", "unmuted");
    this.el.classList.add(this.isMuted ? "muted" : "unmuted");
  }

  updateButton() {
    this.setClasses();
    if (this.hasTooltip) {
      this.tooltip.textContent = this.isMuted
        ? this.el.dataset.tooltipOff
        : this.el.dataset.tooltipOn;
      this.el.ariaLabel = this.tooltip.textContent;
    }
  }
}

class CameraButtonComponent extends avControlButtonComponent {
  constructor(...args) {
    const self = super(...args);
    return self;
  }
}

customElements.define("camera-button", CameraButtonComponent, {
  extends: "button",
});

class MicrophoneButtonComponent extends avControlButtonComponent {
  constructor(...args) {
    const self = super(...args);
    return self;
  }
}

customElements.define("microphone-button", MicrophoneButtonComponent, {
  extends: "button",
});
