import Analytics from "../analytics";

const Hooks = {};

Hooks.AnalyticsTracker = {
  mounted() {
    Analytics.addTrackingListener((event_name, properties) => {
      this.pushEvent("track_event", { event_name, properties });
    });
  },
};

export default Hooks;
