import * as Sentry from "@sentry/browser";

const EVENT_NAME = "analytics:trackEvent";

function addTrackingListener(callback) {
  document.addEventListener(EVENT_NAME, (e) => {
    try {
      const {
        detail: { eventName, properties },
      } = e;
      callback(eventName, properties);
    } catch (e) {
      Sentry.captureException(e);
    }
  });
}

function trackEvent(eventName, properties = {}) {
  try {
    const event = new CustomEvent(EVENT_NAME, {
      detail: { eventName, properties },
    });
    document.dispatchEvent(event);
  } catch (e) {
    Sentry.captureException(e);
  }
}

export default { addTrackingListener, trackEvent };
