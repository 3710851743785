import OT from "@opentok/client";
import OpenTok from "./opentok";
import VideoControls from "./controls";
import DeviceSettings from "./device_settings";
import TokboxNetworkMonitor from "./tokbox_network_monitor";
import * as Sentry from "@sentry/browser";
export default class VideoSession {
  constructor() {
    this.publishedPromise = new Promise((resolve, reject) => {
      this.resolvePublishedPromise = resolve;
      this.rejectPublishedPromise = reject;
    });
  }

  init(liveview, publisherOpts, sessionOpts = {}) {
    const { disablePublishing, logPublisherStats } = sessionOpts;
    this.liveview = liveview;
    this.networkMonitor = new TokboxNetworkMonitor({
      liveview: liveview,
      logPublisher: logPublisherStats,
    });
    this.el = this.liveview.el.querySelector("[data-video-session]");
    this.logPublisherStats = logPublisherStats;

    this.liveview.pushEvent("get_publish_info", {}, (reply, _ref) => {
      this.session = OT.initSession(reply.key, reply.session_id);
      this.session.connect(reply.token, (error) => {
        if (error) {
          this._handleError(error);
          return;
        }
        if (disablePublishing) {
          this.resolvePublishedPromise();
          return;
        }
        this.publisher = OT.initPublisher(this.el, publisherOpts, (error) => {
          // https://tokbox.com/developer/sdks/js/reference/ExceptionEvent.html
          // This handles both OT_USER_MEDIA_ACCESS_DENIED and OT_NO_DEVICES_FOUND which share the same error code
          if (error?.code === 1500) {
            document
              .getElementById(this.el.dataset.controlsId)
              .classList.add("hidden");
          } else if (error) {
            this._handleError(error);
            return;
          } else {
            this.deviceSettings = new DeviceSettings(
              this.publisher,
              "device-settings"
            );
            this.videoControls = new VideoControls(
              this.session,
              this.publisher,
              document.getElementById(this.el.dataset.controlsId)
            );
            this.session.on(
              "streamPropertyChanged",
              this._setStreamState.bind(this)
            );
            this.session.publish(this.publisher, this._onPublish);
          }

          this.resolvePublishedPromise();
        });
      });
    });
  }

  _onPublish = (error) => {
    if (error) {
      this._handleError(error);
    } else {
      this.videoControls.init();
      this.videoControls.audioControl.lock(
        this.liveview.el.dataset.audioLocked === "true"
      );
      this.deviceSettings.init();
      this._storeStreamId(this.publisher.streamId);
      this._setStreamState();
      this.networkMonitor.onPublished(this.publisher);
    }
  };

  _handleError = (error) => {
    if (error) {
      this.rejectPublishedPromise(error);
      console.error(error);
    }
  };

  subscribeToStream(stream, subscriptionOpts) {
    return this.publishedPromise.then(() => {
      return this.session.subscribe(
        stream,
        this._subscriberDomId(stream.id),
        subscriptionOpts
      );
    });
  }

  subscribeToStreamId(streamId, subscriptionOpts) {
    return this.publishedPromise
      .then(() => {
        return this.getStream(streamId);
      })
      .then((stream) => {
        return this.subscribeToStream(stream, subscriptionOpts);
      })
      .catch((errorMessage) => {
        const target = document.getElementById(this._subscriberDomId(streamId));
        if (target) {
          Sentry.captureMessage(errorMessage);
        }
      });
  }

  _subscriberDomId(streamId) {
    return `subscriber-stream-${streamId}`;
  }

  _storeStreamId(streamId) {
    setTimeout(() => {
      this.liveview.pushEvent(
        "store_stream_id",
        { stream_id: streamId },
        () => {}
      );
    });
  }

  _setStreamState(event) {
    if (!event || event.stream.id === this.publisher.stream.id) {
      const params = {
        audio_stream: this.publisher.stream.hasAudio,
        video_stream: this.publisher.stream.hasVideo,
      };
      setTimeout(() => {
        this.liveview.pushEvent("set_stream_state", params, () => {});
      });
    }
  }

  setAudioMuted(muted) {
    return this.publishedPromise.then(() =>
      this.videoControls.audioControl.setMuted(muted)
    );
  }

  lockAudio(locked) {
    return this.publishedPromise.then(() =>
      this.videoControls.audioControl.lock(locked)
    );
  }

  unsubscribeStream(streamId) {
    return this.publishedPromise
      .then(() => this.getStream(streamId, 1))
      .then((stream) => {
        this.session.getSubscribersForStream(stream).forEach((subscriber) => {
          this.session.unsubscribe(subscriber);
        });
      })
      .catch((error) => {
        // Prevent unhandled promise rejection
      });
  }

  getStream(streamId, attempts = 10) {
    return new Promise((resolve, reject) => {
      const getStream = (count = 1) => {
        const stream = this.session.streams.get(streamId);
        if (stream) {
          resolve(stream);
        } else if (count < attempts) {
          setTimeout(() => {
            getStream(++count);
          }, 500);
        } else {
          reject(`Can't find stream: ${streamId}`);
        }
      };
      getStream();
    });
  }
}
