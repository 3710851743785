export class SimpleTimer extends HTMLElement {
  get startsAt() {
    return parseInt(this.getAttribute("starts_at"));
  }

  get endsAt() {
    return parseInt(this.getAttribute("ends_at"));
  }

  get timerStatus() {
    return this.getAttribute("timer_status");
  }

  get workshopId() {
    return this.getAttribute("workshop_id");
  }

  static get observedAttributes() {
    return ["starts_at", "ends_at", "timer_status", "workshop_id"];
  }

  attributeChangedCallback() {
    this.render();
  }

  connectedCallback() {
    this.render();
  }

  render() {
    const [minutes, seconds] = this.timeDifference();
    this.innerHTML = `
    <span class="tracking-wider">
    ${this.zeroPad(minutes)}:${this.zeroPad(seconds)} </span>`;
    this.toggleTimer();
  }

  timeDifference() {
    if (this.timerStatus === "running" && this.endsAt <= this.startsAt) {
      this.resetTimer();
      return [0, 0];
    }

    const delta = Math.abs(this.endsAt - this.startsAt);
    const minutes = Math.floor(delta / 60) % 60;
    const seconds = delta - minutes * 60;
    return [minutes, seconds];
  }

  resetTimer() {
    this.dispatchEvent(
      new CustomEvent("reset_timer", {
        detail: { workshop_id: this.workshopId },
      })
    );
  }

  toggleTimer() {
    if (this.timerStatus === "running" && !this._intervalHandler) {
      this.runTimer();
    } else if (this.timerStatus !== "running" && this._intervalHandler) {
      clearInterval(this._intervalHandler);
      this._intervalHandler = null;
    }
  }

  runTimer() {
    this._intervalHandler = setInterval(() => {
      this.setAttribute("starts_at", this.startsAt + 1);
    }, 1_000);
  }

  zeroPad = (num) => String(num).padStart(2, "0");
}

if (!customElements.get("simple-timer")) {
  customElements.define("simple-timer", SimpleTimer);
}

if (window && !window.SimpleTimer) {
  window.SimpleTimer = SimpleTimer;
}
