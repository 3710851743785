export default class PlayControl {
  constructor(el, videoEl) {
    this.el = el;
    this.videoEl = videoEl;
    this.el.addEventListener("click", (event) => {
      event.preventDefault();
      this.videoEl.currentTime = this.videoEl.duration - 10;
      this.videoEl.play();
      this.el.classList.add("hidden");
    });
  }

  show() {
    this.el.classList.remove("hidden");
  }
}
