class ControlButton {
  constructor(session, publisher, el) {
    this.session = session;
    this.publisher = publisher;
    this.el = el;
    this.el.addEventListener("click", this.toggleMuted);
    this.session.on("streamPropertyChanged", this._handleStreamPropertyChange);
    this.hasTooltip = this.el.classList.contains("has-tooltip");
    this.tooltip = this.el.querySelector(".tooltip");
  }

  isMuted = () => false;

  toggleMuted = () => this.setMuted(!this.isMuted());

  updateButton() {
    const muted = this.isMuted();
    this.el.disabled = this.isLocked();
    this.el.classList.remove("muted", "unmuted");
    this.el.classList.add(muted ? "muted" : "unmuted");
    this.el.ariaPressed = muted ? "true" : "false";
    if (this.hasTooltip) {
      this.tooltip.textContent = muted
        ? this.el.dataset.tooltipOff
        : this.el.dataset.tooltipOn;
      if (this.isLocked()) {
        this.tooltip.textContent = this.el.dataset.tooltipDisabled;
      }
      this.el.ariaLabel = this.tooltip.textContent;
    }
  }

  // set muted on init.
  init = () => this.updateButton();

  updatePublisher = (muted) => {};

  setMuted = (muted) => {
    this.el.disabled = true;
    this.updatePublisher(muted);
  };

  isLocked = () => this.el.classList.contains("locked");

  lock(locked) {
    this.el.disabled = locked;
    if (locked) {
      this.el.classList.add("locked");
    } else {
      this.el.classList.remove("locked");
    }
    this.updateButton();
  }

  _handleStreamPropertyChange = (e) => {
    if (
      e.stream.id === this.publisher.stream.id &&
      this.changeProperty === e.changedProperty
    ) {
      this.updateButton();
    }
  };
}

class AudioControl extends ControlButton {
  constructor(session, publisher, el) {
    super(session, publisher, el);
    this.changeProperty = "hasAudio";
  }

  isMuted = () => !(this.publisher.stream && this.publisher.stream.hasAudio);
  updatePublisher = (muted) => this.publisher.publishAudio(!muted);
}

class VideoControl extends ControlButton {
  constructor(session, publisher, el) {
    super(session, publisher, el);
    this.changeProperty = "hasVideo";
  }

  isMuted = () => !(this.publisher.stream && this.publisher.stream.hasVideo);
  updatePublisher = (muted) => this.publisher.publishVideo(!muted);
}

export default class VideoControls {
  constructor(session, publisher, el) {
    this.audioControl = new AudioControl(
      session,
      publisher,
      el.querySelector(".audio")
    );
    this.videoControl = new VideoControl(
      session,
      publisher,
      el.querySelector(".video")
    );
  }

  init = () => {
    [this.audioControl, this.videoControl].forEach((control) => control.init());
  };
}

export { AudioControl, VideoControl };
