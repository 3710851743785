/* istanbul ignore file */
import * as Sentry from "@sentry/browser";
import { BrowserTracing } from "@sentry/tracing";

function notDevelopment() {
  return BetterupWorkshops.environment !== "development";
}

if (notDevelopment()) {
  Sentry.init({
    dsn: "https://a98b012460274595be8bb347b09ced51@o27463.ingest.sentry.io/6200149",
    release: BetterupWorkshops.release,
    environment: BetterupWorkshops.environment,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
    ignoreErrors: [/OT_USER_MEDIA_ACCESS_DENIED/, /OT_NO_DEVICES_FOUND/],
  });
}
