export default class VolumeControl {
  constructor(containerEl, videoElement) {
    this.containerEl = containerEl;
    this.sliderEl = this.containerEl.querySelector("#volume-slider");
    this.btnEl = this.containerEl.querySelector("#toggle-mute");
    this.videoElement = videoElement;
    this.hasTooltip = this.btnEl.classList.contains("has-tooltip");
    this.tooltip = this.btnEl.querySelector(".tooltip");
    this.#addListeners();
    this.#setState();
  }

  isVideoMuted() {
    return this.videoElement.muted;
  }

  #addListeners() {
    this.btnEl.addEventListener("click", (event) => {
      event.preventDefault();
      this.toggleMute();
    });

    this.containerEl.addEventListener("mouseover", () => {
      this.sliderEl.classList.remove("hidden");
    });

    this.containerEl.addEventListener("mouseout", () => {
      this.sliderEl.classList.add("hidden");
    });

    this.sliderEl.addEventListener("input", ({ target: { value } }) => {
      this.videoElement.volume = value;
      if (
        (this.isVideoMuted() && value > 0) ||
        (!this.isVideoMuted() && value == 0)
      ) {
        this.toggleMute();
      }
    });
  }

  toggleMute() {
    this.videoElement.muted = !this.isVideoMuted();
    if (!this.isVideoMuted() && this.videoElement.volume == 0) {
      this.videoElement.volume = 0.5;
    }
    this.#setState();
  }

  #setState() {
    this.sliderEl.value = this.videoElement.volume;
    this.#setBtnState();
  }

  #setBtnState() {
    this.btnEl.classList.remove("muted", "unmuted");
    this.btnEl.classList.add(this.isVideoMuted() ? "muted" : "unmuted");
    this.btnEl.ariaPressed = this.isVideoMuted() ? "true" : "false";
    if (this.hasTooltip) {
      this.tooltip.textContent = this.isVideoMuted()
        ? this.btnEl.dataset.tooltipOff
        : this.btnEl.dataset.tooltipOn;

      this.btnEl.ariaLabel = this.tooltip.textContent;
    }
  }
}
