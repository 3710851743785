import { EmojiButton } from "@joeattardi/emoji-button";

export default function (emojiTrigger, chatMessage) {
  const emojiPicker = new EmojiButton({
    theme: "dark",
    emojisPerRow: 5,
    rows: 4,
    initialCategory: "recents",
  });
  // emoji-picker__search-container
  emojiPicker.search.searchField.setAttribute(
    "aria-label",
    "Emoji search by text"
  );

  emojiPicker.on("emoji", (selection) => {
    chatMessage.value += selection.emoji;
  });

  emojiPicker.on("hidden", () => {
    chatMessage.focus();
  });

  emojiTrigger.addEventListener("click", () =>
    emojiPicker.togglePicker(emojiTrigger)
  );
}
